$ol-counter-width: 21px;
$ul-dot-y-position: calc(1em / 2);
$quote-max-width: $space-small * 70;
$quote-top-offset: math.div($space-xs, 2);

.text-editor-content {
    word-wrap: break-word;

    strong,
    .byline {
        @include body-font-semi-bold;
    }

    p + .byline {
        margin-top: $space-medium;

        @include mq-tablet-portrait {
            margin-top: $space-xl;
        }
    }

    .byline + .byline {
        margin-top: $space-small;
    }

    &.preamble {
        @include divider-top-base;
        display: block !important;

        .svn-icon__arrow {
            width: 7px;
            height: 7px;
            margin: 6px 3px 0 0;
            vertical-align: top;
            float: left;
        }
    }

    ol,
    ul {
        margin-bottom: $space-large;

        li {
            position: relative;
            list-style: none;

            + li {
                margin-top: $space-medium;
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    ul {
        li {
            position: relative;
            padding-left: $space-medium;
        }

        li:before {
            position: absolute;
            top: $ul-dot-y-position;
            left: 0;
            display: block;
            width: $space-xs;
            height: $space-xs;
            border-radius: 50%;
            border: 2px solid currentColor;
            content: '';
        }
    }

    ol {
        counter-reset: list;

        li:before {
            min-width: $ol-counter-width;
            margin-right: $space-xs;
            counter-increment: list;
            content: counter(list) '.';
            color: $no-theme-default;
            font-weight: bold;
        }
    }

    h3 {
        &.small,
        &.mellanrubrik {
            @include divider-bottom-half;
        }
    }

    h2 {
        &.mellanrubrik {
            @include h2-style;
            margin-top: $space-large;
            margin-bottom: $space-medium;
        }
    }

    .quote,
    .quote-byline,
    .call-to-action,
    .byline {
        clear: both;
        display: block !important;
    }

    .quote,
    .quote-byline {
        border-left-width: 0px;
        border-left-style: solid;
        padding-left: $space-xl;
    }

    .quote {
        @include body-font-semi-bold;
        margin: $space-medium 0;

        + p:not(.quote-byline) {
            margin-top: $space-medium;
        }
    }

    .quote:first-child {
        margin-top: 0;
    }

    .quote + .quote-byline {
        @include divider-bottom-small;
        margin-top: -$space-medium;
    }

    .quote:before {
        display: block;
        width: $space-large;
        height: $space-large;
        float: left;
        margin: 0 $space-small 0 0;
        content: '';
        background: url('../../content/img/frontend/quotation.svg') no-repeat;
    }

    .call-to-action {
        padding: $space-small $space-small;
        @include border-thick-left(var(--color-background-primary-inverted));
        background-color: var(--color-background-light) !important;
        font-weight: normal;
    }

    $external-icon-size: 28px;

    p:not(.call-to-action) {
        a[href^="http://"],
        a[href^="https://"],
        a[href^="//"] {
            @include link-style;

            &.external-link {
                &:hover {
                    opacity: 0.8;
                }

                &:after {
                    position: relative;
                    top: -2px;
                    display: inline-block;
                    vertical-align: middle;
                    width: $external-icon-size;
                    height: $external-icon-size;
                    content: '';
                    background-color: var(--color-foreground-primary);
                    mask: url(../../content/img/frontend/external.svg) no-repeat;

                    @include mq-internet-explorer {
                        background: url('../../content/img/frontend/external.svg') no-repeat;
                    }
                }
            }
        }
    }
}

.rtl {
    .text-editor-content {
        li {
            padding-left: unset;
            padding-right: $space-medium;
        }
        li:before {
            left: unset;
            right: 0;
        }
    }
}


@include mq-mobile-large {
    .main {
        .rtl .text-editor-content {
            p {
                margin-right: 0;
            }
        }

        .text-editor-content {
            p {
                margin-right: 25%;
            }
        }
    }

    .l-article-left-column .text-editor-content {
        p {
            margin-right: 0;
        }
    }
}

@include mq-tablet-portrait {
    .main .text-editor-content {
        p {
            margin-right: 0;
        }
    }

    .text-editor-content {
        .quote {
            max-width: $quote-max-width;
        }

        .call-to-action {
            padding: $space-small;
        }
    }
}
